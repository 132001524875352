import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import login from "../login.vue";
import doc_author from "../components/doc_author_article.vue";
import doc_user from "../components/doc_user.vue";
import doc_excel from "../components/doc_excel.vue";
import doc_notice from "../components/doc_notice.vue";
import doc_post from "../components/doc_post.vue";
import doc_contribute from "../components/doc_contribute.vue";
import doc_userWord from "../components/doc_userWord.vue";
import userIndex from "../components/userIndex.vue";
import doc_set from "../components/doc_set.vue";
import doc_account from "../components/doc_account.vue";
import doc_backup from "../components/doc_backup.vue";
import doc_email from "../components/doc_email.vue";
import doc_email_journal from "../components/doc_email_journal.vue";
import doc_doc_version from "../components/doc_doc_version.vue";
import doc_crawler from "../components/doc_crawler.vue";
import doc_writer from "../components/doc_writer.vue";

const router = createRouter({
  routes: [
    {
      path: "/",
      component: login,
    }, //默认进入login页面
    {
      path: "/admin",
      name: "admin",
      component: () => import("../admin.vue"),
      meta: {
        title: "主页",
        requireAuth: true,
      },
      children: [
        {
          path: "/doc_writer",
          component: doc_writer,
          name: "doc_writer",
        },
        {
          path: "/doc_author",
          component: doc_author,
          name: "doc_author",
        },
        {
          path: "/doc_crawler",
          component: doc_crawler,
          name: "doc_crawler",
        },
        {
          path: "/doc_doc_version",
          component: doc_doc_version,
          name: "doc_doc_version",
        },
        {
          path: "/doc_backup",
          component: doc_backup,
          name: "doc_backup",
        },
        {
          path: "/doc_email_journal",
          component: doc_email_journal,
          name: "doc_email_journal",
        },
        {
          path: "/doc_email",
          component: doc_email,
          name: "doc_email",
        },
        {
          path: "/doc_account",
          component: doc_account,
          name: "doc_account",
        },

        {
          path: "/userIndex",
          component: userIndex,
          name: "userIndex",
        },
        {
          path: "/doc_set",
          component: doc_set,
          name: "doc_set",
        },
        {
          path: "/doc_notice",
          component: doc_notice,
          name: "doc_notice",
        },
        {
          path: "/doc_excel",
          component: doc_excel,
          name: "doc_excel",
        },
        {
          path: "/doc_post",
          component: doc_post,
          name: "doc_post",
        },
        {
          path: "/doc_contribute",
          component: doc_contribute,
          name: "doc_contribute",
        },
        {
          path: "/doc_userWord",
          component: doc_userWord,
          name: "doc_userWord",
        },
        {
          path: "/doc_user",
          component: doc_user,
          name: "doc_user",
        },
      ],
    },
  ],
  history: createWebHashHistory(),
});
//引入nprogress
import NProgress from "nprogress"; // 进度条
import "nprogress/nprogress.css"; //这个样式必须引入
// 简单配置
NProgress.inc(0.2);
NProgress.configure({
  easing: "ease",
  speed: 500,
  showSpinner: false,
});

// 当路由请求之前

router.beforeEach((to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();
  // 这个一定要加，没有next()页面不会跳转的
  next();
});
// 当路由请求之后：关闭进度条
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});
export default router;
